import GalnceLoadingAnimation from "../../../../static/lottie/glance-loading.json";
import React from "react";
import { css } from "emotion";
import colors from "../../common/colors";
import styled from "@emotion/styled";
import font from "../../common/font";

import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

const Container = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: 100%;
  padding: ${props =>
    props.screenHeight ? `${0.05 * props.screenHeight}px 40px` : `5vh 40px`};
  top: 0;
  width: 100%;
  position: absolute;
  width: 100%;
  height: 100;
  background: ${colors.redRibbon};
  padding-top: 240px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-image: linear-gradient(to bottom, #fc024d, #a7002e);
  z-index: 1;
  max-width: 450px;
`;

const Content = styled.div`
  transition: opacity 1s ease-out;
  opacity: 1;
`;

const Text = styled.div`
  font-family: Urbanist;
  width: 280px;
  font-size: ${parseInt(font.fontSmall) * 2 + 2}px;
  font-weight: 800;
  line-height: 1.23;
  letter-spacing: -1.04px;
  text-align: center;
  color: ${colors.white};
  position: fixed;
  top: 390px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const GlanceLoading = ({ isFadingOut }) => {
  const heading = `Aapke Phone Mein <br/> Hai Kya?`;
  return (
    <Container data-testid="glance-loading">
      <Content
        className={
          isFadingOut &&
          css`
            opacity: 0;
          `
        }
      >
        <Lottie
          animationData={GalnceLoadingAnimation}
          className={css`
            width: 150px;
            height: 150px;
            margin: 0 auto;
            top: 230px;
            position: fixed;
            left: 50%;
            transform: translate(-50%, 0);
          `}
        />
        <Text dangerouslySetInnerHTML={{ __html: heading }}></Text>
      </Content>
    </Container>
  );
};

export default GlanceLoading;
