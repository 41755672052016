import React from "react";
import ErrorAnimation from "../../../../static/lottie/error.json";
import { css } from "emotion";

import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });


const SuccessIcon = ({ width, minHeight, margin }) => {
  return (
    <Lottie
      animationData={ErrorAnimation}
      className={css`
        width: ${width};
        min-height: ${minHeight};
        margin: ${margin};
      `}
    />
  );
};

export default SuccessIcon;
